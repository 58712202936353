.pageInfoBanner {
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
    justify-content: space-around;
    
}

.pageInfoBanner span {
    padding-top: 10px;
}