.banner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: end;
  align-self: center;
  background: #fff;
  min-height: 130px;
}

.banner a {
  font-size: 16px;
  color: #999ba7;
  text-transform: uppercase;
  padding: 15px;
}

.svgicon_loc {
  width: 25px;
  height: 25px;
  vertical-align: top;
  fill: #999ba7;
}

.navigation {
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  flex-direction: row;
  left: 0;
  font-size: 16px;
  z-index: 1000;
}

.navigation a:hover {
  background-color: #999ba73a;
  text-decoration: none;
}
.costom_row a:active {
  background-color: #999ba717;
}

.logo-duerr {
  margin: 25px;
  width: 7%;
  right: 0;
  position: fixed;
}

.LanguagePickerWrapper {
  transition: opacity 0.5s;
  opacity: 1;
}
.LanguagePickerWrapper.hide {
  opacity: 0;
  pointer-events: none;
}

.nav_li {
  cursor: pointer;
}
