.costom_row_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.costom_row {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-self: baseline;
  width: 50%;
  background: #fff;
  -webkit-box-shadow: 0 11px 12px 2px rgba(0, 0, 0, 0.175);
  box-shadow: 0 11px 12px 2px rgba(0, 0, 0, 0.175);
}

.costom_row span:hover {
  background-color: #999ba73a;
  cursor: pointer;
}

.costom_row span:active {
  background-color: #999ba717;
}

.navigation__link {
  display: block;
  position: relative;
  margin: 0;
  padding: 12px 18px 12px 20px;
  color: #999ba7;
  outline: 0;
  text-decoration: none;
}
