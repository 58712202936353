.list-inline,.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    margin-left: -5px
}

.list-inline>li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px
}


.footer { 
    font-size: 16px;
    background-color: #fff;
    padding: 40px;
    margin-top: 70px
}

@media (min-width:992px) {
    .footer {
        padding: 60px
    }
}


.footer__nav {
    width: 100%;
    margin-bottom: 35px
}

@media (min-width:640px) {
    .footer__nav {
        text-align: left
    }
}

.footer__nav>li {
    display: block;
    text-align: left;
    margin-bottom: 30px
}

@media (min-width:640px) {
    .footer__nav>li {
        display: inline-block;
        text-align: left;
        line-height: 24px;
        margin-bottom: 0;
        padding-right: 30px
    }
}

@media (min-width:992px) {
    .footer__nav>li {
        padding-right: 70px
    }
}

.footer__meta {
    text-align: left;
    margin: 0;
    font-size: 12px
}

@media (min-width:640px) {
    .footer__meta {
        font-size: 16px
    }
}

.footer__meta>li {
    padding: 0
}

.footer__meta>li:nth-child(even) {
    padding: 0 2px
}

.footer__icontext {
    text-transform: uppercase;
    color: #999ba7;
    padding-left: 15px
}

.footer__logo .svgicon-tx-udgpackage-logo-group {
    width: 150px;
    height: 40px;
    margin-top: 55px
}

.footerlink {
    text-transform: uppercase;
    color: #314f69
}

.footerlink:focus,
.footerlink:hover {
    color: #41698c
}

.footerlink--gray {
    color: #9697a3
}

.footerlink--gray:focus,
.footerlink--gray:hover {
    color: #7b7c8b
}