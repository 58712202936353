a {
    color: #008dca;
    text-decoration: none
}

a:focus,a:hover {
    color: #009fe3;
    text-decoration: underline
}

body {
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 1.5;
    color: #375169;
    background-color: #f6f7fb;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
    margin-top: 100px;
}